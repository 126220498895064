<template>
  <div id="existing-files">
    <b-row class="filters mt-3" align-h="center">
      <b-button-group size="md">
        <b-button
          :variant="type === 'doc' ? 'primary' : 'default'"
          @click="changeType('doc')"
        >
          Documentos
        </b-button>
        <b-button
          :variant="type === 'exam' ? 'primary' : 'default'"
          @click="changeType('exam')"
        >
          Exames
        </b-button>
      </b-button-group>

      <b-col cols="5" class="input-container">
        <Search class="input-icon" />
        <b-form-input
          type="search"
          autocomplete="off"
          id="procedure-search"
          v-model="filters.name"
          :debounce="500"
          placeholder="Buscar nome do arquivo"
        />
      </b-col>

      <b-col cols="3">
        <Periods @select="handlePeriod" />
      </b-col>
    </b-row>
    <b-container class="files mt-4" fluid>
      <div
        v-if="isLoading"
        class="d-flex justify-content-center align-items-center mt-2"
      >
        <b-spinner variant="primary" />
      </div>
      <NoFilterResults v-else-if="!isLoading && !Object.keys(files).length" />

      <perfect-scrollbar ref="scroll">
        <div class="file-container">
          <FileList v-for="file in files" :key="file.id" :file="file">
            <label class="checkbox">
              Adicionar arquivo
              <input
                type="checkbox"
                :checked="fileIsIncludeInArray(file[0].id)"
                @input="filesToAdd(file[0].id)"
              />
              <span class="checkmark">
                <Check />
              </span>
            </label>
          </FileList>
        </div>
      </perfect-scrollbar>
    </b-container>

    <b-row align-h="between" class="p-3">
      <b-col cols="9">
        <span class="file-quantity">
          {{ fileIds.length }} arquivos selecionados
        </span>
      </b-col>
      <b-col class="ml-auto">
        <b-button
          variant="primary"
          class="ml-auto"
          @click="addFiles"
          :disabled="!fileIds.length"
        >
          Adicionar arquivos
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ExistingFiles',
  props: {
    patientId: {
      type: String,
      required: true
    },
    surgeryInformationId: {
      type: String,
      required: true
    },
    generalSheetId: String
  },
  components: {
    NoFilterResults: () => import('@/components/General/noFilterResults'),
    Search: () => import('@/assets/icons/search.svg'),
    Periods: () => import('@/components/General/Periods'),
    FileList: () => import('@/components/General/FileList'),
    Check: () => import('@/assets/icons/check.svg')
  },
  data: () => ({
    files: [],
    isLoading: false,
    type: 'doc',
    filters: {
      name: '',
      period: {
        start: null,
        end: null
      }
    },
    fileIds: [],
    sent: false
  }),
  async created() {
    await this.getDocumentsByPatientAndFilter()
  },
  methods: {
    async getDocumentsByPatientAndFilter() {
      this.isLoading = true
      this.files = []
      try {
        const { data } = await this.api.getDocumentsByPatientAndFilter(
          this.patientId,
          this.surgeryInformationId,
          this.type,
          this.filters
        )
        this.files = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.isLoading = false
      }
    },
    async addFiles() {
      const isLoading = this.$loading.show()
      try {
        await this.api.bulkCreateSurgeryInformationFiles({
          fileIds: this.fileIds,
          surgery_information_id: this.surgeryInformationId,
          surgery_record_id: this.generalSheetId
        })
        this.$toast.success('Arquivos adicionados com sucesso')
        this.removeSelectedFilesFromList()
        this.sent = true
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    removeSelectedFilesFromList() {
      this.fileIds.forEach(item => this.$delete(this.files, item))
      this.fileIds = []
    },
    handlePeriod(period) {
      this.filters.period.start = period.start
      this.filters.period.end = period.end
    },
    changeType(type) {
      this.type = type
    },
    filesToAdd(id) {
      if (this.fileIds.includes(id)) {
        this.fileIds = this.fileIds.filter(fileId => fileId !== id)
        return
      }
      this.fileIds.push(id)
    },
    fileIsIncludeInArray(id) {
      return this.fileIds.includes(id)
    }
  },
  watch: {
    filters: {
      async handler() {
        await this.getDocumentsByPatientAndFilter()
      },
      deep: true
    },
    type: {
      async handler() {
        await this.getDocumentsByPatientAndFilter()
      }
    },
    sent(value) {
      this.$emit('change-sent', value)
    }
  }
}
</script>

<style lang="scss">
#existing-files {
  .filters {
    .btn-default {
      background-color: var(--neutral-100) !important;
    }
    .input-container {
      position: relative;

      .input-icon {
        width: 24px;
        height: 24px;
        stroke: var(--neutral-500);
        margin-right: 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
      }

      .form-control {
        padding: 11px 16px 11px 48px;
      }
    }
  }

  .files {
    .file-container {
      max-height: 470px;
    }
  }
  .file-quantity {
    font-weight: 700;
    font-size: min(1vw, 14px);
    color: var(--type-active);
  }
}
</style>
